:root {
	--clr-neutral-900: hsl(207, 19%, 9%);
	--clr-neutral-100: hsl(0, 0%, 100%);
	--clr-accent-400: hsl(142, 90%, 61%);
	--clr-registered: hsla(142, 90%, 61%, 0.445);
	--clr-unregister: hsl(0, 81%, 56%);
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

#competition {
	display: grid;
	min-height: 100vh;
	place-items: center;
	line-height: 1.6;
	background: var(--clr-neutral-900);
	font-family: sans-serif;
	padding-right: 15em;
}

.card {
	color: var(--clr-neutral-100);
	background-size: cover;
	padding: 10rem 0 0;
	width: 25vw;
	border-radius: 0.5rem;
	overflow: hidden;
	transition: transform 500ms ease;
}

.card:hover,
.card:focus-within {
	transform: scale(1.05);
}

.card-content {
	--padding: 1.5rem;
	padding: var(--padding);
	color: white;
	background: linear-gradient(
		hsl(0 0% 0% / 0),
		hsl(20 0% 0% / 0.3) 20%,
		hsl(0 0% 0% / 1)
	);
}

.card-title {
	position: relative;
	width: max-content;
}

.card:hover .card-title::after,
.card:focus-within .card-title::after {
	transform: scaleX(1);
}

.card-title::after {
	content: "";
	position: absolute;
	height: 4px;
	width: calc(100% + var(--padding));
	left: calc(var(--padding) * -1);
	bottom: -2px;
	background: var(--clr-accent-400);
	transform-origin: left;
	transition: transform 500ms ease;
}

@media (hover) {
	.card-content {
		transform: translateY(65%);
		transition: transform 500ms ease;
	}

	.card-content > *:not(.card-title) {
		opacity: 0;
		transition: opacity 500ms linear;
	}

	.card:hover .card-content > *:not(.card-title),
	.card:focus-within .card-content > *:not(.card-title) {
		opacity: 1;
		transition-delay: 700ms;
	}

	.card:hover .card-content,
	.card:focus-within .card-content {
		transform: translateY(0);
		transition-delay: 500ms;
	}

	.card:focus-within .card-content {
		transition-duration: 0ms;
	}

	.card-title::after {
		transform: scaleX(0);
	}
}

.button {
	cursor: pointer;
	display: inline-block;
	text-decoration: none;
	color: var(--clr-neutral-900);
	background-color: var(--clr-accent-400);
	padding: 0.5em 1.25em;
	border-radius: 0.25rem;
	margin: 1vw;
}

.button:hover,
.button:focus {
	background-color: var(--clr-neutral-100);
}

.button.registered {
	cursor: default;
	background-color: var(--clr-registered) !important;
}

.button.unregister {
	background-color: var(--clr-unregister);
}

.card-body {
	color: rgb(255, 255, 255/0.85);
}

@media (prefers-reduced-motion: reduce) {
	*,
	*::before,
	*::after {
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
		transition-delay: 0ms !important;
	}
}

@media screen and (max-width: 950px) {
	.card {
		width: 40vw;
	}
}

@media screen and (max-width: 680px) {
	.card {
		width: 80vw;
	}
	.detailCard{
		width: 80% !important;
	}
	.closeButton{
		height: 30px !important;width: 30px !important;
	}
}
.events .event-gallery img{
	box-shadow: 2px 2px 2px black;
}
.events .event-gallery img:hover{
	transition: 1s;
	box-shadow: 5px 5px 2px black;
}

.detailCardContainer{
	z-index: 2;
	position: absolute;
	top: 0;left:0;
	height: 100%;width: 100%;
	padding-top: 2vh;
	background-color: rgba(0, 0, 0, 0.459);
}
.detailCard{
	position: relative;
	color: white;
	width: 50%;
	height: 80%;
	margin: auto;
	background: linear-gradient(251.9deg, rgba(76, 18, 77, 0.65) -6.07%, rgba(13, 4, 27, 0.65) 47.04%, rgba(70, 18, 72, 0.65) 86.81%);
    border-radius: 50px;
    border-top:4px solid rgba(255, 255, 255, 0.06);
	opacity:1;
}
.detailCardListItem{
	margin-top: 20px;
}
.closeButton{
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	right: 0;
	margin: 10px;
	height: 40px;width: 40px;
	padding: 5px;
	border: none;
	border-radius: 50%;
	background-color: rgba(255, 0, 0, 0.5);
	color: white;
	cursor: pointer;
}
