@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');


.events{
    width: 100%;
    height: 100vh;
    color: white;
}
.events .heading{
    margin-top: 40vh;
}
.events .heading a{
    text-decoration: none;
    color: white;
    font-size: 6vw;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;

}
.events .event-gallery{
    margin: 5vw 3vw 0vw 3vw;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.events .event-gallery img{
    height: 20vw;
    width: 20vw;
    margin-bottom: 1vw;
    box-shadow: 3px 3px 10px 1px rgba(255, 255, 255, 0.75);
}
.events .event-gallery img:hover{
    border-radius: 0 10px 0 10px;
    transition: 0.5s;
    box-shadow: 5px 5px 5px 1px rgba(255, 255, 255);
}
.events .event-gallery .event:hover{
    transform: scale(1.2);
    transition: 0.5s;
}

.events .event{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.events .event a{
    text-decoration: none;
    color: white;
    font-size: 2vw;
    font-weight: 500;
    margin-bottom: 50px;
}

@media screen and (max-width: 1100px){
    .events .heading a{
        font-size: 7vw;
    }
    .events .event a{
        font-size: 3vw;
    }
    .events .event-gallery{
        margin: 11vw 3vw 0vw 3vw;
    }
    .events .event-gallery img{
        height: 23vw;
        width: 23vw;
    }

}

@media screen and (max-width: 775px){
    .events .heading a{
        font-size: 10vw;
    }
    .events .event a{
        font-size: 6vw;
        margin-bottom: 6vw;
    }
    .events .event-gallery{
        margin: 11vw 3vw 0vw 3vw;
    }
    .events .event-gallery img{
        height: 40vw;
        width: 40vw;
    }
}
/* @media screen and (max-width: 485px){
    .events .heading{
        margin-top: 15vw;
    }
    .events .heading a{
        font-size: 8vw;
    }
    .events .event img{
        height: 30vw;
        width: 30vw;
    }
    .events .event-gallery{
        margin-left: 6vw;
    }
    .events .event a{
        font-size: 4vw;
    }
} */
@media screen and (max-width: 530px) {
    .events{
        height: 80vh;   
    }
    .events .event-gallery{
        margin: 11vw 3vw 0vw 0vw;
    }
    .events .heading{
        margin-top: 30vw;
    }
    .events .heading a{
        font-size: 12vw;
    }
    .events .event img{
        height: 43vw;
        width: 43vw;
        margin-bottom: 3vw;
    }
    .events .event-gallery{
        margin-left: 7vw;
    }
    .events .event a{
        font-size: 6vw;
        margin-bottom: 20vw;
    }
    
}
.exploreMore{
    margin: auto;
}

