.contain {
    position: relative;
    /* width: 1927px; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 4%;
   height: 100vh;
   background-image: url('../img/Clubs\ \(1\).png');
   background-size: 100% 100%;
   align-items: center;
}


.description {
    /* position: relative; */
    /* Set font-related styles, text content, and color */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 3.5vh;
    line-height: 5vh;
    /* text-align: justify; */
    color: #F8F8F8;
    flex: 1 1 300px;
}

.shilp-logo{
    flex: 0 0 auto; /* Prevent image from stretching */
    margin-right: 20px;
   
}



@media screen and (max-width: 1200px) {
    .contain {
        display: flex;
        flex-direction: row; /* Stack elements in a column */
        align-items: center;
        justify-content: center;
        margin-bottom: 20%;
    }

    .shilp-logo,
    .description {
        flex: 0 0 auto; /* Reset flex properties to default */
        width: auto; /* Reset width */
        text-align: left; /* Align text to the left */
        margin-bottom: 0px; /* Remove margin between image and text */
        margin-left: 4%;
        margin-right: 4%;
    }

    .shilp-logo {
        margin-bottom: 48px; /* Add spacing below the centered image */
    }

    .description{
        width: 100%;
        font-size: 2.5vw;
        line-height: 4vw;
    }
}

.shilp-logo img{
    width: 32vw;
    height: auto;
}

@media screen and (max-width: 500px){
    .contain{
         background-size: 100% 100%;
    }
    .description{
        margin-top: -35vw;
        font-size: 4.4vw;
        line-height: 8vw;
    }
    .shilp-logo img{
        margin-top: -10vh;
        width: 80vw;
        height: 80vw;
    }
}