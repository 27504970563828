.footer{
    position: absolute;
    bottom: -50vh;
    left: 0;
    width: 100%;
    min-height: 18vw;
    max-height: 100%;
    padding-bottom: 1vw;
    background: url(../img/footer\ bg.png);
    background-size: cover;
    background-repeat: no-repeat;

    display: grid;
    grid-template-columns: 34vw 0.2vw 34vw 0.2vw 31vw;
}
.footer .line{
    border-radius: 50px;
    margin: 3vw 0 1vw 0;
    background-color: white;
}
.footer .mail-section{
    padding-top: 3vw;
}
.footer .image img{
    height: 11vw;
}
.footer .image .chineseTemple{
    display: none;
}
.footer a{
    text-decoration: none;
    color: white;
    font-size: 2vw;
    font-weight: 700;
}

.footer .subscription{
    padding-top: 2vw;
    color: white;
    font-size: 1.8vw;
    font-weight: 700;
}
.footer .subscription .subscribe-mobile{
    display: none;
}
.footer .subscription img{
    height: 8.5vw;
}
.footer .subscription input{
    margin-top: 1vw;
    width: 19vw;
    height: 2.8vw;
    border-radius: 10px;
    border: 2px solid white;
    background: transparent;
    text-align: center;
    color: white;
    font-size: 1.4vw;
}
.footer .subscription input::placeholder{
    color: white;
    font-size: 1.2vw;
    opacity: 0.7;
}

.footer .connect{
    padding-top: 4.5vw;
}
.footer .connect img{
    height: 7vw;
}
.footer .connect .text{
    margin-top: -1vw;
    color: white;
    font-size: 2vw;
    font-weight: 700;
}
.footer .connect .mobile-text{
    display: none;
}
.footer .connect .social-media-icons a{
    font-size: 2.6vw;
    color: white;
}
.footer .connect .social-media-icons .linkedIn:hover{
    color: #0077b5;
    transform: scale(1.2);
}
.footer .connect .social-media-icons .instagram:hover{
    
    background: #f09433; 
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
    background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
    border-radius: 15px 15px 15px 15px;
    transform: scale(1.2);
}
.footer .connect .social-media-icons .facebook:hover{
    color: 	#1877F2;
    transform: scale(1.2);
}
.youtube:hover{
    color: #CD201F;
}

@media screen and (max-width:505px){
    .footer{
        min-height: 20vw;
    }
    .footer .mail-section .mail a{
        font-size: 3vw;
    }
    .footer .subscription .subscribe{
        font-size: 2.5vw;
    }
    .footer .subscription input{
        width: 90%;
        height: 4vw;
        font-size: 3.6vw;
    }
    .footer .subscription input::placeholder{
        font-size: 3vw;
    }
    .footer .mail-section .mail{
        margin-top: -1.3vh;
    }
    .footer .connect .text{
        font-size: 3vw;
    }
    .footer .connect .social-media-icons{
        margin-top: -0.5vh;
    }
    .footer .connect .social-media-icons a{
        font-size: 3.8vw;
    }
}

/* -----------MOBILE VIEW----------- */
@media screen and (max-width:400px){
    .footer .line{
        margin-top: 1vh !important;
    }
    .footer{
        overflow: hidden;
        min-height: 25vw;
        max-height: 100%;
        grid-template-columns: 28vw 0.1vw 40vw 0.1vw 31vw;
        bottom: -15vh;
    }
    .footer .image .chineseTemple{
        display: block;
        margin: auto;
        height: 16vw;
    }
    .footer .mail-section .mail a{
        font-size: 3.3vw;
        font-weight: 600;
    }
    .footer .image .temple{
        display: none;
    }
    .text{
        display: none;
    }
    
    .footer .subscription .subscribe{
        display: none;
    }
    .footer .subscription .subscribe-mobile{
        display: block;
        font-size: 2.7vw;
        
    }
    .footer .subscription img{
        height: 10vw;
    }

    .footer .subscription input{
        border: 1.6px solid white;
        height: 6vw;
        width: 90%;
    }
    .footer .subscription input::placeholder{
        color: white;
        font-size: 3vw;
        opacity: 0.8;
    }

    .footer .connect .mobile-text{
        display: block;
        margin-top: 0vh;
        padding-left: 5px;
        color: white;
        font-size: 4vw;
        font-weight: 600;
    }
    .footer .connect img{
        margin-top: -2vw;
        height: 13vw;
    }
    .footer .connect .social-media-icons{
        margin-top: -2vh;
    }
    .footer .connect .social-media-icons a{
        font-size: 3.8vw;
    }
    .footer .line{
        margin-top: 0;
        margin-bottom: 0;
        height: 98%;
        padding-left: 2px;
    }
}
@media screen and (max-width:273px){
    .footer .connect .social-media-icons{
        margin-top: -2.5vh;
    }
}