@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
.imageGallery{
    margin-top: 5vh;
    margin-bottom: 5vh;
    z-index: 99;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.subtitle{
    color: #9b7fae;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 5vw;
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 5vh;
}
@media screen and (max-width: 600px) {
    .imageGallery{
        margin-top: 20vh;
    }
    .subtitle{
        font-size: 9.7vw;
    }
    .mySwiper2 img{
        height: 40vh;
    }
    
}