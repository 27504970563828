.gallerycontain{
    margin-top: 20vh;
}
.imgGallery img{
    width:28vw;
}

.imgGallery{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3vw; /* Adjust as needed */
    margin: 5vw;
}

.galimg{
    border-radius: 10px;
}

@media screen and (max-width:900px) {
    .imgGallery{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 3vw; /* Adjust as needed */
        margin: 5vw;
    }
    .imgGallery img{
        width:40vw;
    }
}

@media screen and (max-width:600px) {
    .imgGallery{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 3vw; /* Adjust as needed */
        margin: 5vw;
    }
    .imgGallery img{
        width:80vw;
    }
}