.emptynote{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.headTop{
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(45deg,#d80000,rgb(66, 3, 154),#d80000,rgb(66, 3, 154),#d80000,white);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 100%;
    height: 100vh;
    font-weight: 800 !important;
    font-size: 10vw !important;
}