.shilp-team{
    background-image: url(../img/Clubs\ \(1\).png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow-x: hidden;
    margin-top: 10vh;
}
.team-title{
    position: relative;
    color: white;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 6px rgb(221, 206, 206);

}
.team-title::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgb(125, 11, 148),rgb(216, 193, 219));
    clip-path: circle(20% at right 80%);
}
.team-title::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgb(233, 31, 48),rgb(216, 193, 219));
    clip-path: circle(10% at left 25%);
    z-index: 0;
}
.team-title h1{
    margin-top: 2vw;
}
.team-container{
    position: relative;
    z-index: 1;
    width: 85%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 40px;
    margin-bottom:40px;
}
.team-container .team-card{
    position: relative;
    width: 300px;height: 400px;
    background: rgba(255, 255, 255, 0.05);
    margin: 20px;
    box-shadow: 5px 10px 20px rgba(255, 255, 255, 0.2);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    backdrop-filter: blur(10px);
    transition: 1s;
}
.team-container .team-card:hover{
    box-shadow: 0 15px 20px rgba(255, 255, 255, 0.362);
}
.team-container .team-card .team-content{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0.8;
    transition: 0.5s;
}

.team-container .team-card:hover .team-content{
    opacity: 1;
    transform: translateY(-20px);
}
.team-container .team-card .team-content .imgBx{
    position: relative;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    overflow: hidden;
    border: 10px solid rgba(0,0,0,0.25);
}
.team-container .team-card .team-content .imgBx img{
    position: absolute;
    top: 0;left: 0;width: 100%;height: 100%;
    object-fit: cover;
}
.team-container .team-card .team-content .contentBx{
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 18px;
    text-align: center;
    margin: 2opx 0 10px;
}
.team-container .team-card .sci{
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.team-container .team-card .sci li{
    list-style: none;
    margin: 0 10px;
    transform: translateY(40px);
    transition: 0.5s;
    opacity: 0;
}
.team-container .team-card:hover .sci li{
    transform: translateY(0px);
    opacity: 1;
}
.team-container .team-card .sci li a{
    color: #fff;
    font-size: 25px;
}



.team-header{
    margin: 5vh 0 5vh 0;
}
.team-header h1{
    color: #D12156;
    font-size: 6vw;
    font-weight: 900;
}
.shilp-team .team-title{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    min-height: 100vh;
}

.sci .linkedIn:hover{
    color: #0077b5;
    transform: scale(1.2);
}
.sci .instagram:hover{
    
    background: #f09433; 
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
    background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
    border-radius: 15px 15px 15px 15px;
    transform: scale(1.2);
}
.sci .gmail:hover{
    color: 	red;
    transform: scale(1.2);
}

@media screen and (max-width: 1295px) {
    .team-title::after{
        clip-path: circle(10% at left 5%);
    }
    
}
@media screen and (max-width: 625px){
    .team-title::after{
        display: none;
    }
    .team-title::before{
        clip-path: circle(5% at right 80%);
    }
    .team-header h1{
        font-size: 10vw;
    }
}