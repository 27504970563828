#contact{
    background-image: url(../img/Clubs.png);
    min-height: 100vh;
    max-height: 100%;
}
.contactusTop{
    margin-top: 12vh;
    height: 100%;
    display: flex;
    justify-content: center;
    color: red;
    height: 4vw;
    font-weight: 800 !important;
    font-size: 9vw !important;
    padding-top: 2vw;
    padding-bottom: 8vw;
    margin-bottom: 4vw;
}


.details{
    color: white;
    display: flex;
    justify-content: space-between;
    margin-left: 15vw;
    margin-right: 15vw;
    border: 1px rgb(44, 42, 42) solid;
    border-radius: 8px;
    margin-bottom: 1vw;
    
}


.col2{
    
    display: flex;
    justify-content: center;
    width: 30%;
}

.topp{
    font-size: 3vw;
    font-weight: 700;
    opacity: 0;
    animation: display 0.5s ease-in-out forwards;
}
.s-no1{
    opacity: 0;
    animation: display 0.5s 0.2s ease-in-out forwards;
}
.s-no2{
    opacity: 0;
    animation: display 0.5s 0.4s ease-in-out forwards;
}
.s-no3{
    opacity: 0;
    animation: display 0.5s 0.6s ease-in-out forwards;
}
.s-no4{
    opacity: 0;
    animation: display 0.5s 0.8s ease-in-out forwards;
}
.s-no5{
    opacity: 0;
    animation: display 0.5s 1s ease-in-out forwards;
}
.s-no6{
    opacity: 0;
    animation: display 0.5s 1.2s ease-in-out forwards;
}
.s-no7{
    opacity: 0;
    animation: display 0.5s 1.4s ease-in-out forwards;
}
.s-no8{
    opacity: 0;
    animation: display 0.5s 1.6s ease-in-out forwards;
}




.outer-body{
    background: rgba(54, 24, 68, 1);
    width: 95%;
    min-height: 100vh;
    max-height: 100%;
    margin: auto;
    margin-top: 20vh;
    padding: 4vw;
    
}
.outer-body .inner-body{
    background: rgb(0, 0, 0, 0.4);
    padding: 9vh 6vw 6vw 6vw;
    font-weight: 700;
    min-height: 85vh;
    max-height:100%;
    text-align: justify;
    color: white;
}

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed{
    margin-bottom: 5vh;
    margin-top: 5vh;
    padding: 5vh 0 5vh 5vh;
    background: rgb(33, 15, 42);
    color: white;
    font-weight: 700;
}
.accordion-button::after {
    color: white;
    background-image: url('../img/arrow-down-short.svg') !important;
    margin-right: 1vh;
}
.accordion-body{
    background: rgb(33, 15, 42);
    color: white;
    text-align: justify;
}
.accordion-flush .accordion-item{
    background: rgba(54, 24, 68, 1);
    border: none !important;
}
.contact-inner-body{
    max-height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
    background: rgba(54, 24, 68, 1) !important;
    padding: 0 !important;
}
.contact-inner-body .contact{
    margin: 2vw;
    background: rgb(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.contact-inner-body .contact a{
    text-decoration: none;
    color: white;
    margin-top: 1vh;
    margin-bottom: -1.5vh;
}

@media screen and (max-width: 970px) {
    .contact-inner-body{
        height: 200% !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        background: rgba(54, 24, 68, 1) !important;
        padding: 0 !important;
    }
    .outer-body .contact-outer-body{
        height: 100% !important;
    }
    .contact-inner-body .contact{
        margin: 2vw;
        margin-top: 6vw;
        background: rgb(0, 0, 0, 0.4);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 3vw;
    }
    .contact-inner-body .contact a{
        text-decoration: none;
        color: white;
        margin-top: 1vh;
        margin-bottom: -1.5vh;
    }
    
}
@media screen and (max-width: 456px) {
    .contact-inner-body .contact a{
        font-size: 4vw;
    }
}
