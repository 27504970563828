.accommodation-heading{
    background: rgba(17, 4, 20, 1);
    width: 95%;
    margin: auto;
    margin-top: 15vh;
    color: white;
    border-radius: 50px;
    padding:1vw 0 1vw 0;
}
.accommodation-heading h1{
    font-weight: 800;
    font-size: 5vw;
}
.accommodation-heading span{
    color: rgba(209, 33, 86, 1);
}
.accommodation-tabs{
    background: rgba(17, 4, 20, 1);
    width: 95%;
    margin: auto;
    margin-top: 10vh;
    border-radius: 50px;
    padding: 15vh 3vw 8vh 3vw;
}
.accommodation-tabs .buttons{
    display: flex;
    justify-content: space-evenly;
}
.buttons .btn{
    margin: 1vw;
    color: rgba(255, 255, 255, 1);
    font-weight: bold;
    font-size: 2.3vw;
    min-height: 5vw;
    max-height: 100%;
}
.buttons .btn:hover{
    color: rgb(197, 142, 204);
}
.footer{
    margin-top: 90vh;
}
.buttons .btn:nth-child(1){
    opacity: 0;
    animation: display 0.5s ease-in-out forwards;
}
.buttons .btn:nth-child(2){
    opacity: 0;
    animation: display 0.5s 0.3s ease-in-out forwards;
}
.buttons .btn:nth-child(3){
    opacity: 0;
    animation: display 0.5s 0.6s ease-in-out forwards;
}
.buttons .btn:nth-child(4){
    opacity: 0;
    animation: display 0.5s 0.9s ease-in-out forwards;
}
.buttons .btn:nth-child(5){
    opacity: 0;
    animation: display 0.5s 1.2s ease-in-out forwards;
}
.buttons .btn:nth-child(6){
    opacity: 0;
    animation: display 0.5s 1.5s ease-in-out forwards;
}
@keyframes display {
    0%{
        opacity: 0;
        transform: translateX(-100%);
    }
    100%{
        opacity: 1;
        transform: translateX(0px);
    }
}

@media screen and (max-width: 1200px){
    .accommodation-heading h1{
        font-size: 8vw;
    }
    .accommodation-tabs .buttons{
        display: grid;
        grid-template-columns: 33% 33% 33%;
        grid-template-rows: 50% 50%;
    }
    .buttons .btn{
        font-size: 3.5vw;
        padding-bottom: 1vw;
    }
    
}
.accommodationPolicies-inner-body{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 5vw !important;
    font-weight: 700;
}
