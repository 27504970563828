.events-buttonContainer{
    width: 100vw;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}
.events-button{
    --top : rgba(100,100,100,.8) ;
    --bottom : rgba(100,100,100,.2) ;
    --side : rgba(100,100,100,.5) ;

    --1_top : linear-gradient(rgb(167, 6, 6),rgba(239, 117, 117, 0.67)) ;
    --1_bottom : linear-gradient(rgb(171, 26, 74),rgba(241, 138, 150, 0.314)) ;
    --1_side : rgba(254, 254, 254, 0.842) ;

    --2_top : linear-gradient(rgb(35, 80, 116),rgba(114, 186, 244, 0.67)) ;
    --2_bottom : linear-gradient(rgb(9, 43, 124),rgba(114, 186, 244, 0.67)) ;
    --2_side : rgb(255, 255, 255) ;

    --3_top : linear-gradient(rgb(10, 86, 25),rgb(116, 236, 46)) ;
    --3_bottom : rgba(0,170,100,.2) ;
    --3_side : rgb(255, 255, 255) ;

    --4_top : linear-gradient(rgb(170, 129, 14),rgb(247, 255, 4)) ;
    --4_bottom : rgba(255,230,20,.2) ;
    --4_side : rgb(255, 255, 255) ;

    --5_top : linear-gradient(rgb(74, 5, 106),rgb(238, 110, 221)) ;
    --5_bottom : rgba(155,50,255,.2) ;
    --5_side : rgb(255, 255, 255) ;
}

.events-button{
    display : flex ;
    position : relative ;
    width : 280px ;
    height : 80px ;
    text-decoration : none ;
    perspective : 600px ;
    background : linear-gradient( rgba(255, 255, 255, 0.349) 0% , rgba(255,255,255,0) 30%  ,rgba(255,255,255,0) 70% , rgba(255,255,255,0.1)) ;
    box-shadow : 0 0 32px rgba(0,0,0,1) ;
}
.events-button::before,
.events-button::after{
    content : '' ;
    position : absolute ;
    top : 0 ;
    left : 0 ;
    right : 0 ;
    margin : 0 auto ;
    width : 100% ;
    height : 100% ;    
}
.events-button::before{
    top : 0 ;
    transform-origin : top center ;
    transform : rotateX(70deg) ;
    background : linear-gradient(135deg , rgba(255,255,255,.3) 10%, rgba(255,255,255,0) 60%);
}
.events-button::after{
    bottom : 0 ;
    transform-origin : bottom center ;
    transform : rotateX(-110deg) ;
    background : radial-gradient( rgba(255,255,255,0) 40%, rgba(255, 255, 255, 0.1) ) ;
}
.events-button__wrapper{
    position : relative ;
    z-index : 2 ;
    width : 100% ;
    height : 100% ;
    background : linear-gradient( rgba(255,255,255,.4) 0% , rgba(255,255,255,0) 30%  ,rgba(255,255,255,0) 70% , rgba(255,255,255,.2)) ;
    transform : translate3d( 0 , 27px , 74px) ;
    perspective : 600px ;
}
.events-button__text{
    position : relative ;
    display : flex ;
    justify-content : center ;
    align-items : center ;
    width : 100% ;
    height : 100% ;
    font-family: 'Alfa Slab One', cursive;
    font-size : 32px ;
    letter-spacing : 4px ;
    color : rgba(255, 255, 255, 0.719) ;
    transition : all ease-in-out 1s ;
}
.events-button__text::before{
    content : '' ;
    position : absolute ;
    z-index : 1 ;
    top : 0 ;
    left : 0 ;
    width : 230px ;
    height : 80px ;
    background : linear-gradient(25deg , rgba(255,255,255,.1) , rgba(255,255,255,0)) ;
    clip-path : path('M230,0H0V80H146.96c0-35.33,33.95-65.83,83.04-80Z') ;
}
.events-button__text::after{
    content : '' ;
    position : absolute ;
    z-index : 2 ;
    top : 4px ;
    left : 4px ;
    width : 272px ;
    height : 40px ;
    background : linear-gradient(25deg , rgba(255,255,255,.3) , rgba(255,255,255,0)) ;
    clip-path : path('M272,9.22V0H0V40H.32C48.19,22.59,151.14,10.26,272,9.22Z') ;
}
.events-button__wrapper::before,
.events-button__wrapper::after{
    content : '' ;
    position : absolute ;
    top : 0 ;
    width : 84px ;
    height : 100% ;
    background : linear-gradient(90deg , rgba(255,255,255,.2) 0% , rgba(255,255,255,0) 30%  ,rgba(255,255,255,0) 70% , rgba(255,255,255,.2)) ;
    background : radial-gradient( rgba(255,255,255,0) 40%, rgba(255,255,255,.1) ) ;
}
.events-button__wrapper::before{
    left : 0 ;
    transform-origin : left center ;
    transform : rotateY(90deg) skewY(-20deg);
}
.events-button__wrapper::after{
    right : 0 ;
    transform-origin : right center ;
    transform : rotateY(-90deg) skewY(20deg);
}

.events-button__box{
    position : absolute ;
    top : 0 ;
    left : 8px ;
    bottom : 0 ;
    margin : auto 0 ;
    width : 264px ;
    height : 70px ;
    transform : translate3d(0, 4px, 10px) ;
    perspective : 600px ;
}
.inner{
    transition : all ease-in-out 1s ;
}
.inner__back{
    position : absolute ;
    top : 0 ;
    left : 0 ;
    width : 0 ;
    height : 100% ;
    background : var(--bottom);
    perspective : 600px ;
}
.inner__top{
    position : absolute ;
    z-index : 3 ;
    top : 0 ;
    left : 0 ;
    width : 0 ;
    height : 100% ;
    transform-origin : top left ; 
    transform : rotateX(70deg) ;
    background : var(--top);
}
.inner__front{
    position : absolute ;
    z-index : 3 ;
    top : 0 ;
    left : 0 ;
    width : 0 ;
    height : 100% ;
    transform-origin : top left ; 
    transform : translate3d(0, 24px, 68px ) ;
    background : var(--top);
}
.inner__bottom{
    position : absolute ;
    z-index : 2 ;
    top : 0 ;
    left : 0 ;
    width : 0 ;
    height : 100% ;
    transform-origin : bottom left ; 
    transform : rotateX(-110deg) ;
    background : var(--bottom);
}
.inner__left{
    position : absolute ;
    z-index : 2 ;
    top : 0 ;
    left : 0 ;
    width : 68px ;
    height : 100% ;
    transform-origin : center left ; 
    transform : rotateY(-90deg) skewY(20deg) ;
    background : rgba(255,0,0,0) ;
    transition : all linear .01s ;
}
.inner__right{
    position : absolute ;
    z-index : 2 ;
    top : 0 ;
    left : 0 ;
    width : 68px ;
    height : 100% ;
    transform-origin : center left ; 
    transform : rotateY(-90deg) skewY(20deg) ;
    background : rgba(255,0,0,0) ;
}
.events-button:hover .inner__top,
.events-button:hover .inner__front,
.events-button:hover .inner__bottom,
.events-button:hover .inner__back{
    width : 100% ;
}
.events-button:hover .inner__left{
    background :  var(--side) ;
}
.events-button:hover .inner__right{
    left : 100% ;
    background :  var(--side) ;
}
.events-button:hover .events-button__text{
    color : rgba(255,255,255,1) ;
}
.plate{
    position : absolute ;
    z-index : 10 ;
    top : 0 ;
    left : -20px ;
    width : 320px ;
    height : 120px ;
    animation-duration : 3s ;
    animation-iteration-count : infinite ;
}
.plate:nth-child(1){
    animation-name : plate_1 ;
}
.plate:nth-child(2){
    animation-name : plate_2 ;
}
.plate:nth-child(3){
    animation-name : plate_3 ;
}
.plate:nth-child(4){
    animation-name : plate_4 ;
}
.plate:nth-child(5){
    animation-name : plate_5 ;
}
.events-button:hover .plate{
    animation-play-state : paused ;
}
.plate:nth-child(1):hover ~ .events-button__box .inner__top,
.plate:nth-child(1):hover ~ .events-button__box .inner__front{
    background : var(--1_top);
} 
.plate:nth-child(1):hover ~ .events-button__box .inner__left,
.plate:nth-child(1):hover ~ .events-button__box .inner__right{
    background : var(--1_side);
} 
.plate:nth-child(1):hover ~ .events-button__box .inner__back,
.plate:nth-child(1):hover ~ .events-button__box .inner__bottom{
    background : var(--1_bottom);
} 
.plate:nth-child(2):hover ~ .events-button__box .inner__top,
.plate:nth-child(2):hover ~ .events-button__box .inner__front{
    background : var(--2_top);
} 
.plate:nth-child(2):hover ~ .events-button__box .inner__left,
.plate:nth-child(2):hover ~ .events-button__box .inner__right{
    background : var(--2_side);
} 
.plate:nth-child(2):hover ~ .events-button__box .inner__back,
.plate:nth-child(2):hover ~ .events-button__box .inner__bottom{
    background : var(--2_bottom);
} 
.plate:nth-child(3):hover ~ .events-button__box .inner__top,
.plate:nth-child(3):hover ~ .events-button__box .inner__front{
    background : var(--3_top);
} 
.plate:nth-child(3):hover ~ .events-button__box .inner__left,
.plate:nth-child(3):hover ~ .events-button__box .inner__right{
    background : var(--3_side);
} 
.plate:nth-child(3):hover ~ .events-button__box .inner__back,
.plate:nth-child(3):hover ~ .events-button__box .inner__bottom{
    background : var(--3_bottom);
} 
.plate:nth-child(4):hover ~ .events-button__box .inner__top,
.plate:nth-child(4):hover ~ .events-button__box .inner__front{
    background : var(--4_top);
} 
.plate:nth-child(4):hover ~ .events-button__box .inner__left,
.plate:nth-child(4):hover ~ .events-button__box .inner__right{
    background : var(--4_side);
} 
.plate:nth-child(4):hover ~ .events-button__box .inner__back,
.plate:nth-child(4):hover ~ .events-button__box .inner__bottom{
    background : var(--4_bottom);
} 
.plate:nth-child(5):hover ~ .events-button__box .inner__top,
.plate:nth-child(5):hover ~ .events-button__box .inner__front{
    background : var(--5_top);
} 
.plate:nth-child(5):hover ~ .events-button__box .inner__left,
.plate:nth-child(5):hover ~ .events-button__box .inner__right{
    background : var(--5_side);
} 
.plate:nth-child(5):hover ~ .events-button__box .inner__back,
.plate:nth-child(5):hover ~ .events-button__box .inner__bottom{
    background : var(--5_bottom);
} 
@keyframes plate_1{
    0%   { transform : scale(1) ; }
    19.9%{ transform : scale(1) ; }
    20%  { transform : scale(0) ; }
    100% { transform : scale(0) ; }
}
@keyframes plate_2{
    0%   { transform : scale(0) ; }
    19.9%{ transform : scale(0) ; }
    20%  { transform : scale(1) ; }
    39.9%{ transform : scale(1) ; }
    40%  { transform : scale(0) ; }
    100% { transform : scale(0) ; }
}
@keyframes plate_3{
    0%   { transform : scale(0) ; }
    39.9%{ transform : scale(0) ; }
    40%  { transform : scale(1) ; }
    59.9%{ transform : scale(1) ; }
    60%  { transform : scale(0) ; }
    100% { transform : scale(0) ; }
}
@keyframes plate_4{
    0%   { transform : scale(0) ; }
    59.9%{ transform : scale(0) ; }
    60%  { transform : scale(1) ; }
    79.9%{ transform : scale(1) ; }
    80%  { transform : scale(0) ; }
    100% { transform : scale(0) ; }
}
@keyframes plate_5{
    0%   { transform : scale(0) ; }
    79.9%{ transform : scale(0) ; }
    80%  { transform : scale(1) ; }
    100% { transform : scale(1) ; }
}




/* 本体に関係ないスタイル */
.buttonContainer{
    width : 100% ;
    height : 600px ;
    display : flex ;
    flex-direction : column ;
    justify-content : center ;
    align-items : center ;
    background : #222 ;
}


@media screen and (max-width: 1000px) {
    .events-button__text{
        color : rgb(255, 255, 255) ;
    }
}
@media screen and (max-width: 400px){
    .events-buttonContainer{
        margin: auto;
    }
    .events-button{
        width: 70%;
    }
    .events-button__text{
        font-size: 25px;
    }
}