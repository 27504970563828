@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@300;700;&display=swap');

.merch-container {
    position: relative;
    text-align: center;
    font-family: Arial, sans-serif;
    margin-top: 14vw;
    background-image: url(../img/Group\ 7275.png);
    margin-left: 10vw;
    margin-right: 10vw;
    padding-top: 7vw;
    color: rgb(215, 213, 213);
    height: auto;
    border-radius: 3vw;
    padding-bottom: 10vw;
    margin-bottom: 10vw;
}
   
.shop-item {
    display: inline-block;
    width: 28vw;
    margin: 5vw;
    margin-top: 10vw;
    padding: 2vw;
    /* margin-right: 4vw; */
}

.buy-button {
   background-image: url(../img/Rectangle\ 224.png);
   color: white !important;

   font-family: 'Poppins', sans-serif;
   font-weight: 700;
   padding: 8px 16px;
   border-color: rgb(67, 20, 117);
   cursor: pointer;
   border-radius: 0.5vw;
   outline: none;
   background-color: transparent;
    width: 18vw;
   transition: background-color 0.2s ease, box-shadow 0.2s ease;
}
   
.buy-button:hover {
   background-color: white;
   outline: 1px solid white;
   box-shadow: 5px 10px 5px rgba(0, 0, 0, 0.5);
}
   
   
.top{
    font-weight: 500;
    font-size: 3.5vw;
    color: red;
}

.merch-img{
    width: 24vw;
    margin-top: 1vw;
}

.price{
    background-image: url(../img/Rectangle\ 254.png);

    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 2.4vh;

    color: #000000;
    width: 42vw;
    margin-top: 1.5vw;
    height: 24vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 2vw;
    border-radius: 0.5vw;
}

.butcontain{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.strike{
    text-decoration: line-through;
    margin-left: 0.5vw;
    font-weight: 400
}

@media screen and (max-width:1000px) {
    .buy-button{
        border-radius: 1.5vw;
        width: 65vw;
        font-weight: 700;
    }

    .price{
        font-weight: 700;
        display: flex;
        font-size: medium;
        width: 65vw;
        height: 37vh;
        padding: 3px;
    }

    .strike{
        display: none;
    }

    .h2{
        font-weight: 400;
    }

    .top{
        margin-top: 4vw;
    }
    
}