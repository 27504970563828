.profile-card{
    width: 50vw;
    max-width: 100%;
   padding: 0 !important;
   margin-bottom: 20vh;
}
.profile-body{
  padding-bottom: 15vw;
}
.container .row .col-lg-6{
  padding-bottom: 10vh;
}

.gradient-custom {
  /* fallback for old browsers */
  background: #f6d365;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right bottom, rgba(246, 211, 101, 1), rgba(253, 160, 133, 1));

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right bottom, rgba(246, 211, 101, 1), rgba(253, 160, 133, 1))
}
.card-body .row{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.card-body .row .col-6{
  width: 100%;
}
.card-text{
    color:black;
    height:100% !important;
}
.DashboardDescription{
  width: 134%;
  font-size: 17px;
  color:red;
  margin-bottom: 10px;
}
.WorkshopDescription{
  width: 120%;
  font-size: 16px;
  color:red;
  margin-bottom: 10px;
}

@media screen and (max-width:1000px) {
  .profile-card{
    margin: auto;
    width: 80vw;
  }
}

@media screen and (max-width:400px) {
  .profileHeader{
    width: 150% !important;
    font-size: 5vw !important;
  }
  .DashboardHeader{
    width: 160% !important;
  }
  .accommodationHeader{
    width: 160% !important;
  }
  .DashboardDescription{
    width: 165%;
    font-size: 14px;
  }
  .WorkshopDescription{
    width: 140%;
    font-size: 14px;
  }
}
