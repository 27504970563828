@import url('https://fonts.googleapis.com/css2?family=Inter:wght@900&display=swap');


.innovation-odyssey{
    background: linear-gradient(251.9deg, rgba(76, 18, 77, 0.65) -6.07%, rgba(13, 4, 27, 0.65) 47.04%, rgba(70, 18, 72, 0.65) 86.81%);
    min-height: 65vh;
    max-height: 100%;
    width: 90%;
    margin: auto;
    margin-bottom: 30px;
    margin-top: 20vh;
    border-radius: 50px;
    padding-bottom: 1vw;
    border-top:4px solid rgba(255, 255, 255, 0.06);
}
.innovation-odyssey .heading{
    font-family: 'Inter', sans-serif;
    color: white;
    font-size: 4.6vw;
}
.heading div{
    display: none;
}
.innovation-odyssey .heading span{
    color: rgba(209, 33, 86, 1);
}
.innovation-odyssey .desc{
    font-size: 1.7vw;
    color: white;
}

@media screen and (max-width:800px) {
    .innovation-odyssey .heading{
        font-size: 4.8vw;
    }
    .innovation-odyssey .desc{
        font-size: 3.5vw;
    }
}
@media screen and (max-width:360px) {
    .innovation-odyssey .heading{
        font-size: 7vw;
    }
    .innovation-odyssey .heading div{
        margin-top: -12vw;
        display: block;
    }
    .innovation-odyssey .desc{
        margin-top: 15vw;
        font-size: 4vw;
    }
}
